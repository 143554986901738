<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>
      <v-container fluid>
        <v-form
          ref="form"
          v-model="formValid"
          lazy-validation
        >
          <v-row
            justify="center"
          >
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="Description"
                :append-icon="icons.mdiTagTextOutline "
                :rules="[Validaciones.requerido]"
                label="Descripción"
                dense
                required
                hide-details="true"
                outlined
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="Amount"
                :append-icon="icons.mdiCurrencyUsd"
                :rules="[Validaciones.requerido]"
                label="Monto"
                type="number"
                dense
                hide-details="true"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row
            justify="center"
          >
          </v-row>
          <v-row
            justify="center"
          >
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="Quotas"
                :append-icon="icons.mdiTableColumnPlusBefore "
                :rules="[Validaciones.requerido]"
                label="Cuotas"
                type="number"
                dense
                hide-details="true"
                outlined
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="Interest"
                :append-icon="icons.mdiPercentOutline "
                :rules="[Validaciones.requerido]"
                label="Intereses por pago fuera de término"
                dense
                type="number"
                required
                hide-details="true"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row
            justify="center"
          >
          
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="days"
                :append-icon="icons.mdiCalendarBlank"
                :rules="[Validaciones.requerido]"
                label="Días por Cuota."
                type="number"
                dense
                hide-details="true"
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="amountPerQuota"
                :append-icon="icons.mdiCash100"
                :rules="[Validaciones.requerido]"
                label="Monto por Cuota."
                type="number"
                dense
                hide-details="true"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-row class="mt-7 mb-3">
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            depressed
            @click="close()"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="saveCreditNew"
            color="primary"
            :loading="isSaving"
            depressed
            @click="RegisterCredit()"
          >
            Guardar
          </v-btn>
          <v-btn
            v-else
            color="primary"
            :loading="isSaving"
            depressed
            @click="UpdateCredit()"
          >
            Actualizar
          </v-btn>
          <v-spacer></v-spacer>
        </v-row>
      </v-card-actions>
      <v-snackbar
        v-model="Showsnackbar"
        top
      >
        {{ mensajeSnack }}
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            @click="Showsnackbar = false"
          >
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-container>
</template>
<script>
import axios from 'axios'

import {
  mdiTagTextOutline, mdiCurrencyUsd, mdiPercentOutline, mdiTableColumnPlusBefore, mdiCalendarBlank, mdiCash100 
} from '@mdi/js'
// eslint-disable-next-line import/extensions

// eslint-disable-next-line import/extensions

export default {

  data() {
    return {

      icons: {

        mdiTagTextOutline,
        mdiCurrencyUsd,
        mdiPercentOutline,
        mdiTableColumnPlusBefore,
        mdiCalendarBlank,
        mdiCash100, 

      },
      loadingButton: false,
      mensajeSnack: '',
      Showsnackbar: false,
      colorSnack: 'success',
      saveCreditNew: true,
      Description: '',
      Amount: '',
      Quotas: '',
      Interest: '',
      dialog: false,
      formValid: null,
      days: null,
      amountPerQuota: 0,
      isSaving: false,
      CreditForEdit: null,
      Validaciones: {
        requerido: v => !!v || 'Campo Requerido',
      },
    }
  },
  computed: {
    formTitle() {
      return this.$route.params.id > 0
        ? `Editar Crédito Nro.: ${this.$route.params.id}`
        : 'Registrar Crédito'
    },
  },

  mounted() {
    if (this.$route.params.id) { this.getCreditByID(this.$route.params.id) }
  },
  methods: {
    RegisterCredit() {
      this.InsertCredit()
    },
    close() {
      this.$router.push('/credit')
    },
    submitForm() {
      this.dialog = false
      this.$refs.form.reset()
      this.$emit('Recargar')
    },

    async getCreditByID(creditID) {
      this.saveCreditNew = false
      this.url = `${this.$store.getters.urlBase}/api/Credit/creditByCreditId`
      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
        params: { idCredit: creditID },
        url: this.url,
      }
      const respuesta = await axios(this.url, options)
      if (respuesta.status === 200) {
        this.CreditForEdit = respuesta.data
        this.Amount = this.CreditForEdit.amount
        this.Interest = this.CreditForEdit.interest
        this.Quotas = this.CreditForEdit.quotas
        this.Description = this.CreditForEdit.description
        this.days = this.CreditForEdit.daysOfQuotas
        this.amountPerQuota = this.CreditForEdit.amountPerQuota
      } else {
        // this.colorSnack = "red";
        // this.mensajeSnack = respuesta.data;
        // this.Showsnackbar = true;

      }
    },
    async UpdateCredit() {
      if (this.$refs.form.validate()) {
        this.isSaving = true
        this.url = `${this.$store.getters.urlBase}/api/Credit/update`
        const params = {
          creditID: this.$route.params.id,
          amount: this.Amount,
          interest: this.Interest,
          quotas: this.Quotas,
          description: this.Description,
          Daysofquotas: this.days,
          amountPerQuota: this.amountPerQuota,
          PersonIdLogged: parseInt(this.$store.getters.usuario.user_personid),

        }

        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.getters.usuario.token.token}`,
            'content-type': 'application/json',
          },
        }
        await axios
          .post(this.url, params, config)
          .then(
            // eslint-disable-next-line no-return-assign
            response => {
              this.respuesta = response
              this.isSaving = true
              if (this.respuesta.status === 200) {
                this.$store.dispatch('showSnack', {
                  text: 'Actualización de crédito exitosa',
                  color: 'success',
                  timeout: 2500,
                })
                this.isSaving = false
                this.$router.push({ name: 'credit' })
              }
            },
          )
          .catch(e => {
            if (e.response) {
              this.mensajeSnack = e.response.data
            } else {
              this.mensajeSnack = 'Error de conexion'
            }
            this.isSaving = false
            this.$store.dispatch('showSnack', {
              text: this.mensajeSnack,
              color: 'error',
              timeout: 2500,
            })
          })
      }
    },
    async InsertCredit() {
      if (this.$refs.form.validate()) {
        this.isSaving = true
        this.url = `${this.$store.getters.urlBase}/api/Credit`
        const params = {
          description: this.Description,
          amount: parseFloat(this.Amount),
          quotas: parseInt(this.Quotas),
          interest: parseFloat(this.Interest),
          Daysofquotas: parseInt(this.days),
          amountPerQuota: parseFloat(this.amountPerQuota),
          PersonIdLogged: parseInt(this.$store.getters.usuario.user_personid),

        }
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.getters.usuario.token.token}`,
            'content-type': 'application/json',
          },
        }
        await axios
          .post(this.url, params, config)
          .then(
            // eslint-disable-next-line no-return-assign
            response => {
              this.respuesta = response
              this.isSaving = false
              if (this.respuesta.status === 200) {
                this.$store.dispatch('showSnack', {
                  text: 'Creación de crédito exitosa',
                  color: 'success',
                  timeout: 2500,
                })
                this.$router.push({ name: 'credit' })
              }
            },
          )
          .catch(e => {
            if (e.response) {
              this.mensajeSnack = e.response.data
            } else {
              this.mensajeSnack = 'Error de conexion'
            }
            this.$store.dispatch('showSnack', {
              text: this.mensajeSnack,
              color: 'error',
              timeout: 2500,
            })
            this.isSaving = false
          })
      }
    },
  },
}
</script>
<style scoped>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
